import {
  CollectorDetailsResponseDto,
  ConfirmRequisitionRequestDto,
  CreateOrderResponseDto,
  DonorLoginCredentialsRequestDto,
  GenerateDonorPasswordRequestDto,
  GenerateDonorPasswordResponseDto,
  GenerateRequisitionPdfResponseDto,
  GenerateRequisitionRequestDto,
  HandwrittenSignRequestDto,
  SubmitOrderRequestDto,
} from '@careos/toxicology-types';
import { ToxiHttpClient as ToxiClient } from './toxicology-client';

interface FetchResultPdfParams {
  requisitionId: string;
  reportType: 'chiral' | 'DoA' | 'PEth';
  locale: string;
}

const REQUISITIONS_URL = '/requisitions' as const;

export const api = {
  createRequisitionPdf: (body: GenerateRequisitionRequestDto) =>
    ToxiClient.post<GenerateRequisitionPdfResponseDto>(
      `${REQUISITIONS_URL}/generate-requisition`,
      body,
    ),

  createRequisition: (body: SubmitOrderRequestDto) =>
    ToxiClient.post<CreateOrderResponseDto>(`${REQUISITIONS_URL}/submit`, body),

  confirmRequisition: (body: ConfirmRequisitionRequestDto) =>
    ToxiClient.post<void>(`${REQUISITIONS_URL}/confirm-requisition`, body),

  signRequisition: (body: HandwrittenSignRequestDto) =>
    ToxiClient.post<void>(`${REQUISITIONS_URL}/hand-sign-requisition`, body),

  fetchRequisitionPdf: (requisitionId: string) =>
    ToxiClient.get<string>(
      `${REQUISITIONS_URL}/get-requisition-pdf/${requisitionId}`,
    ),

  fetchSignedPdf: (transactionId: string) =>
    ToxiClient.get<string>(
      `${REQUISITIONS_URL}/get-signed-pdf-by-transaction-id/${transactionId}`,
    ),

  donorFetchSignedPdf: (requisitionId: string) =>
    ToxiClient.get<string>(
      `${REQUISITIONS_URL}/donor-signed-requisition-pdf/${requisitionId}`,
    ),

  fetchCollectorDetails: (requisitionId: string) =>
    ToxiClient.get<CollectorDetailsResponseDto>(
      `${REQUISITIONS_URL}/collector-details/${requisitionId}`,
    ),

  fetchResultPdf: ({
    requisitionId,
    reportType,
    locale,
  }: FetchResultPdfParams) =>
    ToxiClient.get<string>(
      `result-pdf/${requisitionId}/${reportType}/${locale}`,
    ),

  getSignedRequisitionPdfByDonorCredentials: (
    requisitionId: string,
    body: DonorLoginCredentialsRequestDto,
  ) =>
    ToxiClient.post<string | undefined>(
      `${REQUISITIONS_URL}/donor-login-to-get-requisition-pdf/${requisitionId}`,
      body,
    ),
  generateDonorPassword: (body: GenerateDonorPasswordRequestDto) =>
    ToxiClient.post<GenerateDonorPasswordResponseDto>(
      `/password/generate`,
      body,
    ),
};
