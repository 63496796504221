<template>
  <div
    data-test-id="header"
    class="sticky top-0 flex h-16 flex-row items-center justify-between space-x-4 border-b bg-white px-2"
  >
    <div class="grow-0">
      <SvgIcon
        data-test-id="header-logo"
        name="abclabsdoa"
        class="left-0 mr-3 h-12 flex-1 cursor-pointer p-2"
        @click="$router.push('/')"
      />
    </div>

    <div class="wide">
      <CareosHeaderWide
        :user="user"
        :filtered-routes="filteredRoutes"
        :show-activate-bankid="showActivateBankid"
        :show-activate-siths="showActivateSiths"
        @logout="triggerLogout"
        @activate-bankid="emits('activate-bankid', $event)"
        @activate-siths="emits('activate-siths', $event)"
      />
    </div>
    <div class="compact">
      <CareosHeaderCompact
        :user="user"
        :show-activate-bankid="showActivateBankid"
        :show-activate-siths="showActivateSiths"
        :filtered-routes="filteredRoutes"
        @logout="triggerLogout"
        @activate-bankid="emits('activate-bankid', $event)"
        @activate-siths="emits('activate-siths', $event)"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { routes } from '@/router';
import { useAuth0 } from '@auth0/auth0-vue';
import { computed } from 'vue';
import SvgIcon from '../SvgIcon.vue';

import CareosHeaderCompact from './CareosHeaderCompact.vue';
import CareosHeaderWide from './CareosHeaderWide.vue';

const { logout, user } = useAuth0();
const props = defineProps<{
  title?: string;
  permissions: string[];
  showActivateBankid: boolean;
  showActivateSiths: boolean;
}>();

const emits = defineEmits<{
  'activate-bankid': [event: unknown];
  'activate-siths': [event: unknown];
}>();

const triggerLogout = () => {
  logout({ logoutParams: { returnTo: window.location.origin } });
};

const filteredRoutes = computed(() =>
  routes
    .filter((route) => route?.meta?.public)
    .filter((route) =>
      route.meta?.permissions?.some((permission) =>
        props.permissions.includes(permission),
      ),
    ),
);
</script>

<style scoped lang="scss">
.wide {
  display: none;
  @media (min-width: 1024px) {
    display: contents;
  }
}

.compact {
  display: contents;
  @media (min-width: 1024px) {
    display: none;
  }
}
</style>
