<template>
  <div class="mx-3 flex items-center justify-between">
    <div class="relative">
      <button
        type="button"
        class="cursor-pointer"
        @click="displayHamburger = !displayHamburger"
      >
        <PrimeIcon v-if="displayHamburger" icon="TIMES" />
        <PrimeIcon v-else icon="BARS" />
      </button>
      <div
        v-if="displayHamburger"
        class="absolute right-0 rounded-lg border bg-white p-8"
      >
        <div class="text-xl">
          {{ user?.name ?? '' }}
        </div>
        <div class="mt-4 text-xl">
          {{ user?.organization?.display_name }}
        </div>
        <div class="my-2 h-[1px] w-full bg-color-grey" />

        <div class="relative flex h-full flex-1 flex-col whitespace-nowrap">
          <TabItem
            v-for="route in filteredRoutes"
            :key="route.path"
            class="my-2 text-left"
            :label="t(route.meta?.i18n ?? '')"
            :is-current="$route.path.startsWith(route.path)"
            :to="route.path"
            @click="displayHamburger = false"
          />
          <button
            v-if="showActivateBankid"
            class="my-2 flex cursor-pointer items-center justify-between rounded px-3 text-left align-middle font-medium hover:bg-careos-purple-light hover:text-careos-purple"
            @click="emits('activate-bankid', $event)"
          >
            {{ t('authentication.activate_bankid') }}
          </button>
          <!-- <button TODO: Uncomment when it is time to go live with SITHS.
            v-if="showActivateSiths"
            class="my-2 flex cursor-pointer items-center justify-between rounded px-3 text-left align-middle font-medium hover:bg-careos-purple-light hover:text-careos-purple"
            @click="emits('activate-siths', $event)"
          >
            {{ t('authentication.activate_siths') }}
          </button> -->
        </div>
        <div class="my-2 h-[1px] w-full bg-color-grey" />

        <div>
          <LanguageSelector class="self-center border-none" />
        </div>

        <div class="my-2 h-[1px] w-full bg-color-grey" />
        <div class="flex-2 flex h-1/2 flex-col">
          <CareosButton
            :label="t('general.log_out')"
            class="w-34 right-0 p-1"
            @click="triggerLogout"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { CareosRouteRecord } from '@/router/types';
import { User } from '@auth0/auth0-spa-js';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import TabItem from '../TabItem.vue';
import CareosButton from '../CareosButton.vue';
import LanguageSelector from '../LanguageSelector.vue';
import { PrimeIcon } from 'careos-vue-components';

const displayHamburger = ref(false);

defineProps<{
  filteredRoutes: CareosRouteRecord[];
  showActivateBankid: boolean;
  showActivateSiths: boolean;
  user?: User;
}>();

const { t } = useI18n();

const emits = defineEmits<{
  logout: [returnTo: string];
  'activate-bankid': [event: unknown];
  'activate-siths': [event: unknown];
}>();

const triggerLogout = () => {
  emits('logout', window.location.origin);
};
</script>
