import {
  BankIdCheckResponseDto,
  isErrorDto,
  type BankIdSignResponseDto,
} from '@careos/types';
import { BankIdSignRequestDto } from '@careos/toxicology-types';

import { ofetch } from 'ofetch';
import { isProd, isStaging } from '../utils/deployment-environment';

const baseURL = isProd
  ? 'https://api.careos.io/v1/toxi-doa'
  : isStaging
    ? 'https://staging-api.careos.io/v1/toxi-doa'
    : 'http://localhost:5019/v1/toxi-doa';

export const initApi = (getAccessToken: () => Promise<string>) => {
  const baseFetch = ofetch.create({
    retry: 0,
    baseURL,
    async onResponse({ response }) {
      const data = await response._data;
      if (isErrorDto(data)) {
        throw data;
      }
    },
    async onRequest({ options }) {
      const accessToken = await getAccessToken();
      options.headers.append('Authorization', `Bearer ${accessToken}`);
    },
  });

  const api = {
    sign: (body: Omit<BankIdSignRequestDto, 'userAgent'>) =>
      baseFetch<BankIdSignResponseDto>(
        `/requisitions/bankid-initiate-sign-requisition`,
        {
          method: 'POST',
          body,
        },
      ),
    check: (orderRef: string) =>
      baseFetch<BankIdCheckResponseDto>(
        `/requisitions/check-bankid-signing/${orderRef}`,
        {
          method: 'POST',
        },
      ),
    cancel: (orderRef: string) =>
      baseFetch(`/requisitions/cancel-bankid-signing/${orderRef}`, {
        method: 'DELETE',
      }),
  };
  return api;
};
