import { FormKitNode } from '@formkit/core';
import { parsePhoneNumber } from 'awesome-phonenumber';
import personnummer from 'personnummer';

export function nameValidation(node: FormKitNode) {
  const nameValidationRegex =
    /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/;
  return nameValidationRegex.test(String(node.value));
}

export function personalNumber(node: FormKitNode) {
  if (typeof node.value !== 'string') {
    return false;
  }

  if (!personnummer.valid(node.value)) {
    return false;
  }

  return true;
}

export function shallowNotEmpty(node: FormKitNode) {
  if (typeof node.value !== 'object') {
    return false;
  }
  if (!node.value) {
    return false;
  }
  const values = Object.values(node.value);
  for (let i = 0; i < values.length; i++) {
    const value = values[i];
    if (!value) {
      return false;
    }
  }
  return true;
}

export function internationalPhoneNumber(node: FormKitNode) {
  if (typeof node.value !== 'string') {
    return false;
  }
  const phoneNumber = parsePhoneNumber(node.value);
  return phoneNumber.valid && phoneNumber.canBeInternationallyDialled;
}
