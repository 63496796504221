<template>
  <OrderViewWrapper v-if="!isFetchingRequisition">
    <OrderViewCard
      class="w-full max-w-6xl"
      data-test-id="confirm-requisition"
      :show-backwards="requisition?.type !== 'signedRequisition'"
      :disable-forwards="
        requisition?.type !== 'signedRequisition' &&
        store.selectedOrderType === 'CoC'
      "
      :forward-label="t('summary.submit_order')"
      :title="t('summary.confirm_requisition')"
      @action-left="router.back()"
      @action-right="showModal = true"
    >
      <PdfEmbed
        v-if="requisition?.pdf"
        class="w-full"
        :pdf-content="requisition.pdf"
        @on-rendered="scrollToBottom"
        @on-update-status="(status) => (pdfStatus = status)"
      />
      <SignatureActions
        v-if="isRequisitionPdfRendered && requisition?.transactionId"
        :has-personal-number="
          store.personalDetails.identifier.system ===
          IdentifierSystem.PersonalIdentityNumber
        "
        class="mt-4"
        :transaction-id="requisition.transactionId"
        @signing-completion="() => handleSigning(requisition!.transactionId)"
      />
      <CareosModal
        :is-loading="isLoadingConfirm"
        :visible="showModal"
        @accept="nextStep"
        @close="showModal = false"
        ><div class="mb-6 mt-6 flex flex-col items-center space-y-5">
          <PrimeIcon icon="QUESTION_CIRCLE" size="LG" />
          <div class="ml-3 text-left">
            {{ collectorApproval }}
          </div>
        </div>
      </CareosModal>
    </OrderViewCard>
  </OrderViewWrapper>
</template>
<script setup lang="ts">
import { useNetworkRequest } from '@/composables/useNetworkRequest';
import { onBeforeRouteLeave } from 'vue-router';
import { router } from '@/router';
import { useSamplingLocationStore } from '@/stores/order/samplingLocationStore';
import { useOrderViewStore } from '@/views/order-view/store/order-view-store';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import OrderViewCard from '../../components/OrderViewCard.vue';
import OrderViewWrapper from '../../components/OrderViewWrapper.vue';
import { GenerateRequisitionPdfResponseDto } from '@careos/toxicology-types';
import SignatureActions from './components/SignatureActions.vue';
import { PrimeIcon } from 'careos-vue-components';
import CareosModal from '@/components/CareosModal.vue';
import { api } from '@/api/client';
import { Locale } from '@careos/types';
import { useToast } from 'primevue/usetoast';
import { watch } from 'vue';
import { IdentifierSystem } from '@abc-labs-ab/ts-events';
import PdfEmbed, { type PdfStatus } from '@/components/PdfEmbed.vue';

const { t, locale } = useI18n();
const store = useOrderViewStore();
const samplingLocationStore = useSamplingLocationStore();
const toast = useToast();

const showModal = ref(false);

const collectorApproval = computed(() =>
  t(
    `summary.collector_approval_${samplingLocationStore.selectedRequisitionType}`,
  ),
);

if (!store.isValid) {
  router.push({ path: '/order/requisition-type' });
}

const { exec: nextStep, isLoading: isLoadingConfirm } = useNetworkRequest(
  async () => {
    if (!collectorApproval.value) return;

    await store.submitRequisition(requisition.value?.transactionId!);
    await store.confirmRequisition(collectorApproval.value);
    store.signedRequisitionPdf = requisition.value?.pdf!;
    await router.push('/order/created');
  },
  {
    customSummary: t('summary.error_message.creating_order'),
  },
);

const pdfStatus = ref<PdfStatus>();
const requisition = ref<
  GenerateRequisitionPdfResponseDto & {
    type: 'requisition' | 'signedRequisition';
  }
>();
const isRequisitionPdfRendered = computed(
  () =>
    !!(
      store.selectedOrderType === 'CoC' &&
      requisition.value?.type !== 'signedRequisition' &&
      pdfStatus.value === 'rendered'
    ),
);

const scrollToBottom = () => {
  if (requisition.value?.type === 'signedRequisition') {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth',
    });
  }
};

const handleSigning = async (transactionId: string) => {
  try {
    const { data } = await api.fetchSignedPdf(transactionId);
    requisition.value = {
      pdf: data,
      transactionId,
      type: 'signedRequisition',
    };
  } catch (e) {
    toast.add({
      severity: 'error',
      summary: t('summary.error_message.signing_requisition'),
      life: 5000,
    });
  }
};

const fetchRequisition = async () => {
  const { transactionId, pdf } = await store.generateRequisition(
    locale.value as Locale,
  );

  requisition.value = {
    transactionId,
    pdf,
    type: 'requisition',
  };
};

const { exec: fetchReq, isLoading: isFetchingRequisition } = useNetworkRequest(
  () => fetchRequisition(),
);

watch(locale, async () => {
  if (requisition.value?.type === 'requisition') {
    await fetchReq();
  }
});

fetchReq();

onBeforeRouteLeave((to) => {
  if (
    !(to.fullPath === '/order/created') &&
    requisition.value?.type === 'signedRequisition'
  ) {
    const answer = window.confirm(t('system.order_summary_leave_warning'));
    if (answer) {
      return true;
    }
    return false;
  }
});
</script>
