<template>
  <OrderViewWrapper>
    <OrderViewCard
      data-test-id="personal-details-view"
      :forward-label="t('general.next_step')"
      :disable-forwards="!personalDetailsForm?.node.context?.state.valid"
      :title="t('personalDetails.title')"
      @action-left="previousStep()"
      @action-right="nextStep()"
    >
      <FormKit ref="personalDetailsForm" v-model="formValues" type="group">
        <div class="flex flex-col">
          <div>
            <FormKit
              name="first_name"
              :label="t('personalDetails.firstName')"
              :placeholder="t('personalDetails.firstName')"
              validation="required:trim|nameValidation|length:0,40"
              :validation-messages="{
                nameValidation: t(
                  'personalDetails.validation.charactersNotLatin',
                ),
              }"
            />
            <FormKit
              name="last_name"
              validation="required:trim|nameValidation|length:0,40"
              :label="t('personalDetails.lastName')"
              :placeholder="t('personalDetails.lastName')"
              :validation-messages="{
                nameValidation: t(
                  'personalDetails.validation.charactersNotLatin',
                ),
              }"
            />

            <FormKit
              :label="phoneNumber.label"
              name="phone_number"
              :validation="phoneNumber.validation"
              :validation-messages="{
                internationalPhoneNumber: t(
                  'personalDetails.validation.phoneNumberNotValid',
                ),
              }"
              type="phoneNumberInput"
            />
          </div>

          <div>
            <FormKit
              type="splitRadio"
              name="nationality"
              :label="t('personalDetails.has_personal_id')"
              :left="{
                label: t('personalDetails.yes'),
                value: IdentifierSystem.PersonalIdentityNumber,
              }"
              :right="{
                label: t('personalDetails.no'),
                value: IdentifierSystem.PatientReference,
              }"
            />

            <FormKit
              v-if="
                formValues.nationality ===
                IdentifierSystem.PersonalIdentityNumber
              "
              id="patient_personal_number"
              name="patient_personal_number"
              inputmode="numeric"
              data-test-id="personal-number"
              :label="t('identifier.personalNumber')"
              :placeholder="t('personalIdentifier.mask')"
              validation="required|personalNumber"
              validation-visibility="blur"
              :onblur="
                () => handlePersonalNumberBlur('patient_personal_number')
              "
              :validation-messages="{
                personalNumber: t('personalDetails.validation.personalNumber'),
              }"
            />
            <FormKit
              v-else
              name="patient_personal_id"
              :label="t('identifier.custom')"
              type="text"
              validation="required"
            />

            <FormKit
              id="personal-details-attester-is-needed"
              :label="t('personalDetails.attester_is_needed')"
              name="should_show_attester_info"
              type="splitRadio"
              :left="{
                label: t('personalDetails.yes'),
                value: true,
              }"
              :right="{
                label: t('personalDetails.no'),
                value: false,
              }"
            />
          </div>
          <div v-if="formValues.should_show_attester_info">
            <FormKit
              name="attester_first_name"
              data-test-id="attester-first-name"
              :label="t('personalDetails.attester.firstName')"
              :placeholder="t('personalDetails.attester.firstName')"
              :validation-messages="{
                alpha: t('personalDetails.validation.charactersNotLatin'),
              }"
              type="text"
              validation="required|alpha_spaces:default|length:0,40"
            />

            <FormKit
              name="attester_last_name"
              data-test-id="attester-last-name"
              :label="t('personalDetails.attester.lastName')"
              :placeholder="t('personalDetails.attester.lastName')"
              :validation-messages="{
                alpha: t('personalDetails.validation.charactersNotLatin'),
              }"
              type="text"
              validation="required|alpha_spaces:default|length:0,40"
            />

            <FormKit
              type="splitRadio"
              name="attester_nationality"
              preserve
              :label="t('personalDetails.attester.hasPersonalNumber')"
              :left="{
                label: t('personalDetails.yes'),
                value: IdentifierSystem.PersonalIdentityNumber,
              }"
              :right="{
                label: t('personalDetails.no'),
                value: IdentifierSystem.PatientReference,
              }"
            />
            <FormKit
              v-if="
                formValues.attester_nationality ===
                IdentifierSystem.PersonalIdentityNumber
              "
              id="attester_personal_number"
              name="attester_personal_number"
              data-test-id="attester-personal-number"
              inputmode="numeric"
              :placeholder="t('personalIdentifier.mask')"
              :label="t('personalDetails.attester.personalNumber')"
              :validation-messages="{
                personalNumber: t('personalDetails.validation.personalNumber'),
              }"
              :onblur="
                () => handlePersonalNumberBlur('attester_personal_number')
              "
              validation="required|personalNumber"
              validation-visibility="blur"
            />
            <FormKit
              v-else
              name="attester_personal_id"
              :label="t('personalDetails.attester.personalId')"
              type="text"
              validation="required"
            />
          </div>
          <FormKit
            id="personal-details-id-check"
            :inner-label="t('personalDetails.patient_identity_verified')"
            :label="t('personalDetails.patient_identification')"
            type="formCheckbox"
            validation="accepted"
            name="id_verification_checkbox"
          />
        </div>
      </FormKit>
    </OrderViewCard>
  </OrderViewWrapper>
</template>
<script setup lang="ts">
import { router } from '@/router';
import { useOrderViewStore } from '@/views/order-view/store/order-view-store';
import { IdentifierSystem } from '@abc-labs-ab/ts-events';
import { FormKitNode, getNode } from '@formkit/core';
import { ref, computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import OrderViewCard from '../../components/OrderViewCard.vue';
import OrderViewWrapper from '../../components/OrderViewWrapper.vue';
import { formatPersonalNumberIfValid } from '@/helpers/formatPersonalNumberIfValid';

const store = useOrderViewStore();
const { t } = useI18n();
const personalDetailsForm = ref<{ node: FormKitNode } | null>(null);

const formValues = ref({
  first_name: store.personalDetails.firstName,
  last_name: store.personalDetails.lastName,
  phone_number: store.personalDetails.phoneNumber,
  nationality: store.personalDetails.identifier.system,
  patient_personal_number: store.personalDetails.identifier.value,
  patient_personal_id: store.personalDetails.identifier.value,
  should_show_attester_info: store.shouldCollectAttesterInfo,
  attester_first_name: store.attester.firstName,
  attester_last_name: store.attester.lastName,
  attester_nationality: store.attester.identifier.system,
  attester_personal_number: store.attester.identifier.value,
  attester_personal_id: store.attester.identifier.value,

  id_verification_checkbox: store.personalDetails.idCheck,
});

const phoneNumber = computed(() => {
  if (store.selectedOrderType === 'non-CoC') {
    return {
      label: t('personalDetails.phoneNumberOptional'),
      validation: 'internationalPhoneNumber',
    };
  }
  return {
    label: t('personalDetails.phoneNumber'),
    validation: 'required|internationalPhoneNumber',
  };
});

const handlePersonalNumberBlur = (id: string) => {
  const node = getNode(id);
  if (!node?.value || typeof node.value !== 'string') return;

  const formattedPersonalNumber = formatPersonalNumberIfValid(node.value);
  node.input(formattedPersonalNumber);
};

const saveFormValuesToStore = () => {
  store.personalDetails.firstName = formValues.value.first_name;
  store.personalDetails.lastName = formValues.value.last_name;
  store.personalDetails.phoneNumber = formValues.value.phone_number;
  store.personalDetails.identifier.system = formValues.value.nationality;
  store.personalDetails.identifier.value =
    formValues.value.nationality === IdentifierSystem.PersonalIdentityNumber
      ? formValues.value.patient_personal_number
      : formValues.value.patient_personal_id;
  store.shouldCollectAttesterInfo = formValues.value.should_show_attester_info;
  store.attester.firstName = formValues.value.attester_first_name;
  store.attester.lastName = formValues.value.attester_last_name;
  store.attester.identifier.system = formValues.value.attester_nationality;
  store.attester.identifier.value =
    formValues.value.attester_nationality ===
    IdentifierSystem.PersonalIdentityNumber
      ? formValues.value.attester_personal_number
      : formValues.value.attester_personal_id;
  store.personalDetails.idCheck = formValues.value.id_verification_checkbox;
};
const previousStep = () => {
  saveFormValuesToStore();
  router.go(-1);
};

const nextStep = () => {
  saveFormValuesToStore();
  router.push({
    path: '/order/reason',
  });
};

window.onbeforeunload = () => {
  if (personalDetailsForm.value?.node.context?.state.dirty) {
    return true;
  }
};

watch(
  () => formValues.value.nationality,
  (newValue, oldValue) => {
    if (newValue !== oldValue) {
      formValues.value.patient_personal_number = '';
      formValues.value.patient_personal_id = '';
    }
  },
);
</script>
<style>
.formkit-help {
  text-align: left;
}
</style>
