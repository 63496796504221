import { Locale } from '@careos/types';
import { useStorage } from '@vueuse/core';

export const useLocaleStorage = () => {
  const i18nStorage = useStorage<{ locale: Locale }>('locale', {
    locale: 'sv-SE',
  });

  const updateLocale = (locale: Locale) => (i18nStorage.value.locale = locale);

  return {
    i18nStorage,
    updateLocale,
  };
};
