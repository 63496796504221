<template>
  <div>
    <div class="flex flex-wrap gap-4">
      <CareosButton
        class="min-w-fit flex-1 py-6"
        :label="t('summary.hand_sign')"
        data-test-id="sign-button"
        @click="showSignaturePadModal = true"
      />
      <CareosButton
        v-if="hasPersonalNumber"
        class="min-w-fit flex-1 py-6"
        :label="t('summary.bankid_sign')"
        data-test-id="bankid-sign-button"
        @click="showBankidModal = true"
      />
    </div>
    <Dialog
      v-model:visible="showBankidModal"
      :draggable="false"
      pt:mask:class="backdrop-blur-sm"
      pt:dialog:class="!border-radius-none"
    >
      <template #container>
        <BankId
          v-if="showBankidModal"
          :transaction-id="transactionId"
          :locale="locale"
          :get-access-token="getAccessTokenSilently"
          @cancel="showBankidModal = false"
          @completion="
            () =>
              handleSigningCompletion({
                signatureType: 'BANKID',
              })
          "
        />
      </template>
    </Dialog>

    <SignaturePadModal
      v-model="showSignaturePadModal"
      @update-signature="
        (signature) =>
          handleSigningCompletion({
            signatureType: 'HANDWRITTEN',
            signature,
          })
      "
    />
  </div>
</template>
<script setup lang="ts">
import CareosButton from '@/components/CareosButton.vue';
import SignaturePadModal from './SignaturePadModal.vue';
import { useI18n } from 'vue-i18n';
import { ref } from 'vue';
import { BankId } from 'careos-vue-components';
import Dialog from 'primevue/dialog';
import { useAuth0 } from '@auth0/auth0-vue';
import {
  HandwrittenSignRequestDto,
  SignatureType,
} from '@careos/toxicology-types';
import { Locale } from '@careos/types';
import { api } from '@/api/client';

const { t, locale } = useI18n<Locale>();

export type Signing =
  | {
      signature: string;
      signatureType: Extract<SignatureType, 'HANDWRITTEN'>;
    }
  | {
      signatureType: Extract<SignatureType, 'BANKID'>;
    };

const emit = defineEmits<{
  signingCompletion: [];
}>();

const props = defineProps<{
  transactionId: string;
  hasPersonalNumber: boolean;
}>();

const { getAccessTokenSilently } = useAuth0();

const showSignaturePadModal = ref(false);
const showBankidModal = ref(false);

const getDimensions = (
  src: string,
): Promise<{ width: number; height: number }> => {
  return new Promise((resolve, reject) => {
    let img = new Image();
    img.onload = () => resolve({ width: img.width, height: img.height });
    img.onerror = reject;
    img.src = src;
  });
};

const handleSigningCompletion = async (data: Signing) => {
  if (data.signatureType === 'BANKID') {
    await new Promise((res) => setTimeout(res, 3000));
    showBankidModal.value = false;
  } else {
    const dimensions = await getDimensions(data.signature);
    const dto: HandwrittenSignRequestDto = {
      transactionId: props.transactionId,
      signature: {
        signatureType: SignatureType.HANDWRITTEN,
        signatureData: data.signature,
        dimensions: dimensions,
      },
    };
    await api.signRequisition(dto);
  }
  emit('signingCompletion');
};
</script>
