import { api } from '@/api/client';
import { ToxiHttpClient } from '@/api/toxicology-client';
import { auth0 } from '@/authentication/auth0';
import { useBarcodeStore } from '@/stores/order/barcode';
import { useSamplingKitStore } from '@/stores/order/samplingKitStore';
import { useSamplingLocationStore } from '@/stores/order/samplingLocationStore';
import { assertDefined } from '@/utils/assert-defined';
import { IdentifierSystem } from '@abc-labs-ab/ts-events';
import {
  DoaActivityDefinitionIdentifier,
  SamplingKitIdentifier,
} from '@careos/identifiers';
import {
  GetEnabledTestTypesForOrganizationResponseDto,
  OrderType,
  OrganizationType,
  OrganizationTypeSchema,
  TestType,
} from '@careos/organization-api-types';
import {
  ConfirmRequisitionRequestDto,
  CreateOrderRequestDto,
  DoaSpecificRequisitionRequest,
  GenerateRequisitionRequestDto,
  GenerateRequisitionRequestDtoSchema,
  GetPanelsResponseDto,
  PanelSubstance,
  PanelType,
  PatientSchema,
  PethSpecificRequisitionRequest,
  ReasonSchema,
  ReasonsForTesting,
} from '@careos/toxicology-types';
import { Locale } from '@careos/types';
import { defineStore } from 'pinia';
import { toRefs } from 'vue';
import { OrderViewState } from './order-view-state';
import {
  createDoAPanelInfo,
  createDoaSpecimenInfo,
  createPethSpecimenInfo,
} from './order-view-store-helpers';

export const useOrderViewStore = defineStore('order-view-store', {
  state: (): OrderViewState => ({
    specimenTypes: [],
    panels: null,
    selectedPanelSize: 'L',
    selectedSpecimen: null,
    selectedAdditionalSubstances: [],
    isChiralOrdered: false,
    personalDetails: {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      identifier: {
        system: IdentifierSystem.PersonalIdentityNumber,
        value: '',
      },
      idCheck: false,
    },
    requisitionTypes: {
      options: OrganizationTypeSchema.options,
    },
    testToOrderTypes: {
      options: [],
      selectedTestToOrderType: null,
    },
    requisitionId: null,
    donorPassword: null,
    comment: { value: null, noPrescriptionsCheckbox: false },
    urineTemperature: null,
    reason: {
      options: [],
      selected: null,
      isOtherSelected: false,
    },
    shouldCollectAttesterInfo: false,
    attester: {
      firstName: '',
      lastName: '',
      identifier: {
        system: IdentifierSystem.PersonalIdentityNumber,
        value: '',
      },
    },
    signedRequisitionPdf: null,
  }),
  getters: {
    panelType: (state): PanelType | undefined =>
      state.selectedSpecimen && state.selectedPanelSize
        ? `${state.selectedSpecimen}:${state.selectedPanelSize}`
        : undefined,
    isValid: (state): boolean => {
      const barcodeStore = useBarcodeStore();
      return (
        Boolean(state.personalDetails) &&
        Boolean(state.selectedSpecimen) &&
        Boolean(barcodeStore.barcodeValue)
      );
    },
    patientName: (state): string =>
      `${state.personalDetails.firstName} ${state.personalDetails.lastName}`,
    selectedTestType: (state): TestType | undefined =>
      state.testToOrderTypes.selectedTestToOrderType?.testType,
    selectedOrderType: (state): OrderType | undefined =>
      state.testToOrderTypes.selectedTestToOrderType?.orderType,
    selectedPanel: (state): PanelSubstance[] | undefined =>
      state.panels && state.selectedPanelSize
        ? state.panels[state.selectedPanelSize]
        : undefined,
    selectedPanelSubstancesNames(): string[] | undefined {
      return this.selectedPanel?.map((it) => it.name);
    },
    selectedAdditionalSubstancesNames: (state): string[] | undefined =>
      state.selectedAdditionalSubstances.map((it) => it.name),
    hasAccreditedSubstance(): boolean {
      return this.selectedPanel
        ? this.selectedPanel.some((substance) => substance.isSwedacAccredited)
        : false;
    },
    hasQualitativeSubstance(): boolean {
      if (!this.panels) return false;
      return Object.values(this.panels)
        .flatMap((it) => it)
        .some((it) => it.isReportedQualitative);
    },
  },
  actions: {
    async fetchDoaEnabledSpecimenTypes(organizationKey: string) {
      const samplingLocationStore = useSamplingLocationStore();
      const organizationType = assertDefined(
        samplingLocationStore.selectedRequisitionType,
      );
      const testType = this.testToOrderTypes.selectedTestToOrderType?.testType;
      const res = await ToxiHttpClient.get<DoaActivityDefinitionIdentifier[]>(
        `/config/organization-enabled-doa-specimen-types/${testType}/${organizationKey}/${organizationType}`,
      );
      this.specimenTypes = res.data;
    },

    async getPanelsBySpecimenType(
      activityDefinition: DoaActivityDefinitionIdentifier,
      organizationType?: OrganizationType,
      samplingKit?: SamplingKitIdentifier,
    ) {
      const res = await ToxiHttpClient.get<GetPanelsResponseDto>(
        `config/panels`,
        {
          params: {
            activityDefinition,
            organizationType,
            samplingKit,
          },
        },
      );

      this.panels = res.data.panels;
      if (!this.panels[this.selectedPanelSize]) {
        this.selectedPanelSize = 'L';
      }
    },
    async fetchReasonOptions() {
      const res = await ToxiHttpClient.get<ReasonsForTesting[]>(
        'config/reasons-for-testing',
      );
      this.reason.options = res.data;
    },
    async fetchEnabledTestTypes() {
      const samplingLocationStore = useSamplingLocationStore();
      const res =
        await ToxiHttpClient.get<GetEnabledTestTypesForOrganizationResponseDto>(
          `organization-api-client/organization-test-type/${samplingLocationStore.selectedRequisitionType}`,
        );
      this.testToOrderTypes.options = res.data.testToOrderTypes;
    },

    setAdditionalSubstances(substances: PanelSubstance[]) {
      this.selectedAdditionalSubstances = substances;
    },

    clearOrder() {
      const barcodeStore = useBarcodeStore();
      const samplingLocationStore = useSamplingLocationStore();
      const samplingKitStore = useSamplingKitStore();
      this.$reset();
      barcodeStore.$reset();
      samplingLocationStore.$reset();
      samplingKitStore.$reset();
    },

    // Clears parts parts of order that are tied to testType
    clearPartialOrder() {
      const samplingLocationStore = useSamplingLocationStore();
      const temp = this.personalDetails;
      const tempTestToOrderType = this.testToOrderTypes;
      const tempReqType = samplingLocationStore.selectedRequisitionType;
      this.$reset();
      this.personalDetails = temp;
      this.testToOrderTypes = tempTestToOrderType;
      samplingLocationStore.selectedRequisitionType = tempReqType;
    },

    async generateRequisition(locale: Locale) {
      const name = assertDefined(auth0.user.value?.name);
      const email = assertDefined(auth0.user.value?.email);
      const nameArr = name.split(' ');
      const given = nameArr.slice(
        0,
        -1,
      ) as CreateOrderRequestDto['practitioner']['name']['given'];
      const family = assertDefined(nameArr.at(-1));
      const identifier = assertDefined(this.personalDetails.identifier);

      const samplingLocationStore = useSamplingLocationStore();

      // Prune the dashes on the value property if the identifier system is PersonalIdentityNumber
      identifier.value =
        identifier.system === IdentifierSystem.PersonalIdentityNumber
          ? identifier.value.replaceAll('-', '')
          : identifier.value;

      const attester: CreateOrderRequestDto['attester'] = this
        .shouldCollectAttesterInfo
        ? {
            name: {
              family: this.attester.lastName,
              given: [this.attester.firstName],
              text: `${this.attester.firstName} ${this.attester.lastName}`,
            },
            identifier: {
              system: this.attester.identifier.system,
              value:
                this.attester.identifier.system ===
                IdentifierSystem.PersonalIdentityNumber
                  ? this.attester.identifier.value.replaceAll('-', '')
                  : this.attester.identifier.value,
            },
          }
        : undefined;

      const getOrderDto = (): GenerateRequisitionRequestDto | undefined => {
        const barcodeStore = useBarcodeStore();
        const samplingKitStore = useSamplingKitStore();
        const { selectedSamplingKit } = toRefs(samplingKitStore);

        const reason = this.reason.isOtherSelected
          ? {
              otherReason: this.reason.selected,
            }
          : this.reason.selected;

        const patient = {
          orderType: this.selectedOrderType,
          name: {
            text: this.patientName,
            family: this.personalDetails.lastName,
            given: [this.personalDetails.firstName],
          },
          identifier,
          telecom: this.personalDetails.phoneNumber
            ? {
                system: 'phone',
                value: this.personalDetails.phoneNumber,
              }
            : undefined,
        };
        const parsedPatient = PatientSchema.parse(patient);

        const baseDto: Partial<GenerateRequisitionRequestDto> = {
          locale,
          barcodeValue: assertDefined(barcodeStore.barcodeValue),
          patient: parsedPatient,
          attester,
          reason: ReasonSchema.parse(reason),
          practitioner: {
            name: {
              text: name,
              family,
              given,
            },
            telecom: {
              system: 'email',
              value: email,
            },
          },
          comment: assertDefined(this.comment.value),
        };
        baseDto.testType = this.selectedTestType;
        baseDto.orderType = this.selectedOrderType;

        baseDto.samplingLocation = samplingLocationStore.samplingLocation;

        this.handleRequisitionByTestType(baseDto, selectedSamplingKit.value);

        const parsedDto = GenerateRequisitionRequestDtoSchema.parse(baseDto);
        return parsedDto;
      };

      const dto = getOrderDto();
      // TODO fix logic in getOrderDto to not return undefined
      const res = await api.createRequisitionPdf(
        dto as GenerateRequisitionRequestDto,
      );
      return res.data;
    },

    async submitRequisition(transactionId: string) {
      const dto = {
        transactionId,
      };
      const res = await api.createRequisition(dto);

      this.requisitionId = res.data.requisitionId;
      this.donorPassword = res.data.donorPassword;
    },

    async confirmRequisition(collectorConfirmationText: string) {
      const requisitionId = assertDefined(this.requisitionId);
      const dto: ConfirmRequisitionRequestDto = {
        collectorConfirmationText,
        requisitionId,
      };
      await api.confirmRequisition(dto);
    },

    handleRequisitionByTestType(
      baseDto: Partial<GenerateRequisitionRequestDto>,
      samplingKit?: SamplingKitIdentifier,
    ) {
      switch (baseDto.testType) {
        case 'DoA':
          this.handleDoaRequisition(baseDto, samplingKit);
          break;
        case 'PEth':
          this.handlePethRequisition(baseDto, samplingKit);
          break;
        default:
          throw new Error('Invalid test type');
      }
    },

    handleDoaRequisition(
      baseDto: Partial<DoaSpecificRequisitionRequest>,
      samplingKit?: SamplingKitIdentifier,
    ) {
      baseDto.isChiralOrdered = this.isChiralOrdered;
      baseDto.panel = createDoAPanelInfo(
        this.selectedPanelSize,
        this.selectedAdditionalSubstances.map((it) => it.name),
      );
      const specimenType = assertDefined(this.selectedSpecimen);
      baseDto.specimenInfo = createDoaSpecimenInfo(
        specimenType,
        this.urineTemperature,
        samplingKit,
      );
    },

    handlePethRequisition(
      baseDto: Partial<PethSpecificRequisitionRequest>,
      samplingKit?: SamplingKitIdentifier,
    ) {
      const specimenType = assertDefined(this.selectedSpecimen);
      baseDto.specimenInfo = createPethSpecimenInfo(specimenType, samplingKit);
    },
  },
});
