<template>
  <div class="py-1">
    <Checkbox
      v-model="isEnabled"
      binary
      :input-id="samplingKit"
      :pt="{
        root: 'w-[24px] h-[24px] rounded-[4px] mr-2',
        box: ({ context }) => ({
          class: [
            {
              'bg-careos-purple border-careos-purple': context.checked,
            },
          ],
        }),
      }"
    />
    <label :for="`rb${samplingKit}`">{{
      t(`samplingKits.${samplingKit}`)
    }}</label>
  </div>
</template>

<script setup lang="ts">
import Checkbox from 'primevue/checkbox';
import { SamplingKitIdentifier } from '@careos/identifiers';
import { useI18n } from 'vue-i18n';
import { ref, watch } from 'vue';
import { SamplingKitArgs } from '@/views/admin-view/composables/useOrganizationTestType';

const props = defineProps<{
  wasAlreadyEnabled: boolean;
  samplingKit: SamplingKitIdentifier;
}>();

const isEnabled = ref(props.wasAlreadyEnabled);
const { t } = useI18n();

const emit = defineEmits<{
  update: [value: SamplingKitArgs];
}>();

watch(isEnabled, (val) => {
  const obj = { samplingKit: props.samplingKit, isEnabled: val };
  emit('update', obj);
});
</script>
